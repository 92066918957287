<template>
  <div class="rank-box">
    <div class="title-box">
      <div class="time">（统计时间段：{{ time }}）</div>
      <div class="tabcur">
        <template v-for="(item, index) in tabList">
          <span @click="tabChange(index)" :class="{ on: tabCur === index }" class="tab-item" :key="index">
            {{ item }}
          </span>
        </template>
      </div>
    </div>
    <div class="rank-scroll">
      <dv-loading v-if="loading">加载中...</dv-loading>
      <div class="empty" v-else-if="list.length == 0">暂无数据</div>
      <swiper v-show="list && list.length" ref="mySwiper" :options="swiperOption" class="ranks-list">
        <swiper-slide v-for="(item, index) in list" :key="index" class="rank-item">
          <div class="item">
            <div class="line"></div>
            <div class="rank">
              <img v-if="item.rank === 1" class="rank-img" :src="require('@/assets/image/crm-rank1.png')" />
              <img v-else-if="item.rank === 2" class="rank-img" :src="require('@/assets/image/crm-rank2.png')" />
              <img v-else-if="item.rank === 3" class="rank-img" :src="require('@/assets/image/crm-rank3.png')" />
              <i v-else class="text">NO.{{ item.rank }}</i>
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="price">
              <scroll-num class="num" :number="toPriceUnit(item.price).num"></scroll-num>
              {{toPriceUnit(item.price).unit}}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { toPriceUnit } from '@/utils/utils.js'
import { getCustomerRank } from '@/api'
import { mapState } from 'vuex'
import ScrollNum from '@/components/ScrollNum'
let size
export default {
  components: {
    swiper,
    swiperSlide,
    ScrollNum
  },
  data() {
    size = Number(document.documentElement.style.fontSize.replace('px', '')) || 16
    return {
      tabList: ['签约', '回款'],
      tabCur: 0,
      time: '',
      list: [],
      swiperOption: {
        // 所有的参数同 swiper 官方 api 参数
        direction: 'vertical',
        slidesPerView: 6,
        spaceBetween: size * 0.75,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },
      loading: false
    }
  },
  computed: {
    ...mapState(['orgId']),
    // 拿到swiper实例
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  watch: {
    // 监听组织架构切换
    orgId: {
      handler() {
        this.getList()
      },
      immediate: true
    }
  },
  methods: {
    toPriceUnit,
    // 切换签约回款
    tabChange(i) {
      this.tabCur = i
      this.getList()
    },
    // 加载数据
    getList() {
      this.list = []
      this.loading = true
      getCustomerRank({ 
        org_id: this.orgId,
        type: this.tabCur + 1
      }).then(res => {
        if (res.data) {
          this.list = res.data.rows
          this.time = res.data.time
          this.swiper.update()
        }
      }).finally(()=>{
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.rank-box {
  .rank-scroll {
    .ranks-list {
      height: 249px;
      .rank-item {
        // opacity: 0.78;
        .item {
          width: 100%;
          height: 100%;
          background: #0a2e69;
          border: 1px solid #1a3f81;
          display: flex;
          align-items: center;
          text-align: left;
          .line {
            border-left: 7px solid #00ffff;
            border-bottom: 3.5px solid transparent;
            border-top: 3.5px solid transparent;
            height: 10px;
            width: 0;
            margin-right: 8px;
          }
          .rank {
            width: 56px;
            .rank-img {
              height: 25px;
            }
            .text {
              font-size: 14px;
              padding: 0;
              color: #fde17b;
            }
          }
          .title {
            font-size: 14px;
            color: #ffffff;
            width: 268px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .price {
            flex: 1;
            font-size: 12px;
            padding-right: 12px;
            text-align: right;
            .num {
              font-weight: 700;
              color: #ff4f66;
            }
          }
        }
      }
    }
  }
}
</style>
