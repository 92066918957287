<template>
  <dv-loading v-if="loading">加载中...</dv-loading>
  <div v-else-if="list && list.length" class="adverts-box">
    <div class="title-box">
      <div class="area-box">
        <div class="area-text">印刷总面积</div>
        <div class="area-num">
          <scroll-num class="number" :number="list[tabCur].print_total"></scroll-num>
          平方米
        </div>
      </div>
      <div class="price-box">
        <div class="ico-box" :style="{ backgroundImage: `url(${require('@/assets/image/adv-icobg.svg')})` }">
          <div class="ico iconfont icon-tab-jiaoyiri"></div>
        </div>
        <div class="text-box">
          <div class="price-text"><scroll-num class="num" :number="total"></scroll-num>万元</div>
          <div class="text">当日广告交易额</div>
        </div>
      </div>
      <div class="area-box">
        <div class="area-text ins">安装总面积</div>
        <div class="area-num">
          <scroll-num class="number" :number="list[tabCur].install_total"></scroll-num>
          平方米
        </div>
      </div>
    </div>
    <div class="tabcur">
      <template v-for="(item, index) in tabList">
        <span @click="tabChange(index)" :class="{ on: tabCur === index }" class="tab-item" :key="index">
          {{ item }}
        </span>
      </template>
    </div>
    <div class="chert-box">
      <div class="chert-title">
        <span>印刷数量</span>
        <span>安装数量</span>
      </div>
      <div class="print" id="print"></div>
      <div class="install" id="install"></div>
    </div>
  </div>
  <div class="empty" v-else>暂无数据</div>
</template>

<script>
import ScrollNum from '@/components/ScrollNum'
import * as echarts from 'echarts'
import clonedeep from 'lodash.clonedeep'
import { getCategoryPublish } from '@/api'
import { mapState } from 'vuex'
export default {
  components: {
    ScrollNum
  },
  data() {
    return {
      myPrint: null,
      myInstall: null,
      total: 0,
      tabList: [], // 时间数组
      tabCur: 0,
      list: [],
      loading: false
    }
  },
  computed: {
    ...mapState(['orgId'])
  },
  watch: {
    // 监听组织架构切换
    orgId: {
      handler(n) {
        this.list = []
        if (n || n === 0) {
          this.loading = true
          getCategoryPublish({ organization_id: n }).then(res => {
            if (res.data) {
              this.list = res.data.rows
              this.total = res.data.total_day
              this.tabList = this.list.map(item => item.time)
              this.loading = false
              this.$nextTick(() => {
                this.tabChange(this.tabCur)
              })
            }
          }).finally(()=>{
            this.loading = false
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    // 切换年周月等，并重新加载图表
    tabChange(i) {
      this.myPrint && this.myPrint.dispose()
      this.myInstall && this.myInstall.dispose()
      this.myPrint = echarts.init(document.getElementById('print'))
      this.myInstall = echarts.init(document.getElementById('install'))
      this.tabCur = i
      this.initEcharts(this.list[i])
    },
    // 初始化图表，左右两边为两个图表拼凑
    initEcharts(data) {
      // 1rem值
      // const size = Number(document.documentElement.style.fontSize.replace('px', '')) || 16
      // 左边图表
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '25%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          inverse: true,
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: {
            color: '#999',
            fontSize: 12
          }
        },
        yAxis: {
          // name: '印刷数量',
          // nameTextStyle: {
          //   color: '#CCC',
          //   fontSize: 14,
          //   align: 'center',
          //   padding: [0, size * 26.25, 0, 0]
          // },
          type: 'category',
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: {
            color: '#999',
            fontSize: 12,
            margin: 30
          },
          data: data.print.map(item => item.name).reverse()
        },
        series: [
          {
            type: 'bar',
            showBackground: true,
            barMinHeight: 1,
            backgroundStyle: {
              color: '#03214b'
            },
            itemStyle: {
              color: '#00e3ff'
            },
            data: data.print.map(item => item.value).reverse()
          }
        ]
      }
      // 右边图表
      const opt = clonedeep(option)
      opt.xAxis.inverse = false
      opt.yAxis.position = 'right'
      opt.yAxis.align = 'left'
      // opt.yAxis.nameTextStyle.padding = [0, 0, 0, size * 26.25]
      // opt.yAxis.name = '安装数量'
      opt.series[0].itemStyle.color = '#007ffe'
      opt.series[0].data = data.install.map(item => item.value).reverse()
      opt.yAxis.data = data.install.map(item => item.name).reverse()
      this.myPrint.setOption(option)
      this.myInstall.setOption(opt)
    }
  }
}
</script>

<style lang="less" scoped>
.adverts-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .title-box {
    display: flex;
    justify-content: space-between;
    text-align: left;
    .area-box {
      width: 134px;
      background: rgba(61, 153, 221, 0.15);
      border-radius: 4px;
      padding: 5px 10px;
      box-sizing: border-box;
      .area-text {
        display: inline-block;
        background: #067684;
        border-radius: 2px;
        font-size: 12px;
        color: #fff;
        padding: 1px 5px;
        margin-bottom: 5px;
        &.ins {
          background: #0d4883;
        }
      }
      .area-num {
        color: #fde17b;
        font-size: 12px;
        text-shadow: 0 0 10px #067684;
        .number {
          font-size: 17px;
          font-weight: 700;
        }
      }
    }
    .price-box {
      display: flex;
      .ico-box {
        width: 54px;
        height: 54px;
        margin-right: 8px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: rotaeRevers 3s linear 0s infinite;
        .ico {
          color: #1ee8ff;
          border: 1px solid;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
           animation: rotaeAll 3s linear 0s infinite;
        }
      }
      .text-box {
        display: flex;
        flex-direction: column;
        .price-text {
          background: linear-gradient(180deg, #5ff2ff 0%, #00e3ff 100%);
          -webkit-background-clip: text;
          color: transparent;
          letter-spacing: -1px;
          .num {
            font-size: 21px;
            font-weight: 700;
          }
        }
        .text {
          margin-top: 4px;
          font-size: 11px;
        }
      }
    }
  }
  .chert-box {
    flex: 1;
    display: flex;
    position: relative;
    .chert-title{
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      font-size: 14px;
      color: #ccc;
      display: flex;
      justify-content: space-between;
    }
    .print,
    .install {
      display: flex;
      margin: 0;
      padding: 0;
      flex: 1;
      width: 50%;
      height: 100%;
    }
    .print{
      justify-content: end;
    }
    .install{
      justify-content: start;
    }
  }
}
</style>
