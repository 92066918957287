import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orgId: 0
  },
  mutations: {
    changeOrg: (state, orgId) => {
      state.orgId = orgId
    }
  },
  actions: {},
  modules: {}
})