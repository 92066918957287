//价格转换成万
export function toPriceW(price = 0){
  if(price >= 10000){
    price /= 10000;
    price.toString().split(".")[1] && price.toString().split(".")[1].length && (price = price.toFixed(1))
  }else{
    price = Number(price).toFixed(2) * 1
  }
  return price
}

export function toPriceUnit(num = 0, decimal = 0) {
  const moneyUnits = ['元', '万元', '亿元', '万亿']
  const result = { 
    num, 
    unit:  moneyUnits[0]
  }
  const dividend = 10000
  //转换单位
  for (let i = 0; i < 4; i++) {
    result.unit = moneyUnits[i]
    if (strNumSize(result.num) < 5) {
      break
    }
    result.num = result.num / dividend
  }
  result.num = result.num.toFixed(decimal)
  return result
}

function strNumSize(tempNum) {
  let stringNum = tempNum.toString()
  let index = stringNum.indexOf('.')
  let newNum = stringNum
  if (index != -1) {
    newNum = stringNum.substring(0, index)
  }
  return newNum.length
}

/**
 * 全屏
 * @param {node} el
 * @returns
 */
export function fullScreen(el) {
  let rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen
  let wscript

  if (typeof rfs !== 'undefined' && rfs) {
    rfs.call(el)
    return
  }

  if (typeof window.ActiveXObject !== 'undefined') {
    // eslint-disable-next-line no-undef
    wscript = new ActiveXObject('WScript.Shell')
    if (wscript) {
      wscript.SendKeys('{F11}')
    }
  }
}
// 退出全屏
export function exitFullScreen() {
  let el = document
  let cfs = el.cancelFullScreen || el.webkitCancelFullScreen || el.mozCancelFullScreen || el.exitFullScreen
  let wscript
  if (typeof cfs !== 'undefined' && cfs) {
    cfs.call(el)
    return
  }
  if (typeof window.ActiveXObject !== 'undefined') {
    // eslint-disable-next-line no-undef
    wscript = new ActiveXObject('WScript.Shell')
    if (wscript != null) {
      wscript.SendKeys('{F11}')
    }
  }
}
