<template>
  <dv-loading v-if="loading">加载中...</dv-loading>
  <div v-else-if="list && list.length" class="traffic-box">
    <div class="total-box">
      <img class="total-img" :style="orgId != 0 ? 'margin-top: 8px' : ''" :src="require('@/assets/image/traffic-line.svg')" alt="全国总数" />
      <img class="total-img" v-if="orgId == 0" :src="require('@/assets/image/traffic-total.svg')" alt="全国总数" />
      <img class="total-img" style="width: 41px;margin-top: 8px;" v-else :src="require('@/assets/image/traffic-total1.svg')" alt="总数" />
      <scroll-num class="flop" :number="total"></scroll-num>
      <div class="text">万人次</div>
    </div>
    <swiper ref="mySwiper" :options="swiperOption" class="gauge-box">
      <swiper-slide v-for="(item, index) in list" :key="index" class="gauge-item">
        <div :id="'gauge' + index" class="gauge"></div>
      </swiper-slide>
    </swiper>
  </div>
  <div class="empty" v-else>暂无数据</div>
</template>

<script>
import * as echarts from 'echarts'
import clonedeep from 'lodash.clonedeep'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import ScrollNum from '@/components/ScrollNum'
import { getTraffic, getTrafficLine } from '@/api'
import { toPriceW } from '@/utils/utils.js'
import { mapState } from 'vuex'
let self
export default {
  components: {
    swiper,
    swiperSlide,
    ScrollNum
  },
  computed: {
    ...mapState(['orgId']),
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  data() {
    self = this
    return {
      swiperIndex: 0,
      swiperOption: {
        // 所有的参数同 swiper 官方 api 参数
        direction: 'horizontal',
        slidesPerView: 2,
        spaceBetween: 0,
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        on: {
          slideChange: function() {
            self.swiperIndex = this.activeIndex
          }
        }
      },
      total: 0,
      max: 0,
      list: [],
      myChartList: [],
      // 七色轮换
      colors: [
        { light: '#00FCFF', dark: '#2681D0', image: require('@/assets/image/traffic-ico1.png') },
        { light: '#FFD600', dark: '#F5A416', image: require('@/assets/image/traffic-ico2.png') },
        { light: '#4BD3FF', dark: '#125FD9', image: require('@/assets/image/traffic-ico3.png') },
        { light: '#576BFF', dark: '#2E1CB4', image: require('@/assets/image/traffic-ico4.png') },
        { light: '#FF649B', dark: '#C52250', image: require('@/assets/image/traffic-ico5.png') },
        { light: '#D978FF', dark: '#631B93', image: require('@/assets/image/traffic-ico6.png') },
        { light: '#36F253', dark: '#1B8F2E', image: require('@/assets/image/traffic-ico7.png') }
      ],
      loading: false
    }
  },
  watch: {
    // 监听组织架构切换
    orgId: {
      handler(n) {
        let submit
        this.list = []
        this.max = 0
        if (n === 0 || n === '0') {
          submit = getTraffic()
        } else if (n) {
          submit = getTrafficLine({ organization_id: n })
        }
        this.loading = true
        submit.then(res => {
          if (res.data) {
            this.list = res.data.rows
            this.total = toPriceW(res.data.total)
            this.loading = false
            res.data.rows.forEach(item => {
              item.value = Number(toPriceW(item.value))
              if (item.value > this.max) {
                this.max = item.value
              }
            })
            if (this.max < 100) {
              this.max = 100
            } else {
              this.max = Math.ceil(this.max * 1.2)
            }
          }
        }).finally(()=>{
          this.loading = false
        })
      },
      immediate: true
    },
    // 监听数据变化
    list: {
      handler(n, o) {
        // 先清楚原本的echarts实例
        if (o && o.length > 0) {
          o.map((item, index) => {
            echarts.init(document.getElementById('gauge' + index)).dispose()
          })
        }
        if (n && n.length > 0) {
          // 等list渲染完成后再渲染新echarts实例并更新swiper实例
          this.$nextTick(() => {
            this.myChartList = n.map((item, index) => {
              const myChart = echarts.init(document.getElementById('gauge' + index))
              const opt = this.initOpt(this.colors[index % 7], item)
              myChart.setOption(opt, item)
              return myChart
            })
            this.swiper.update()
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 初始化图表配置
    initOpt(colors, item) {
      // 1rem值
      const size = Number(document.documentElement.style.fontSize.replace('px', '')) || 16
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: this.max,
            grid: {
              top: 0,
              left: 0,
              right: 0,
              bottom: '40%'
            },
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: colors.light
                  },
                  {
                    offset: 1,
                    color: colors.dark
                  }
                ]
              }
            },
            progress: {
              show: true,
              roundCap: false,
              width: size * 1.5
            },
            axisLine: {
              roundCap: false,
              lineStyle: {
                width: size * 1.5,
                color: [[1, '#051b49']]
              }
            },
            pointer: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
            splitLine: { show: false },
            title: { show: false },
            detail: {
              offsetCenter: [0, '60%'],
              valueAnimation: true,
              formatter: () => {
                return ['{ico|}', `{value|${item.value}}`, '{text|年总客流量(万人次)}', `{org|${item.name}}`].join('\n')
              },
              rich: {
                value: {
                  fontSize: size * 1.25,
                  fontWeight: 'bolder',
                  color: colors.light,
                  lineHeight: size * 3
                },
                text: {
                  color: colors.light,
                  fontSize: size * 0.875
                },
                ico: {
                  backgroundColor: {
                    image: colors.image
                  },
                  height: size * 1.375
                },
                org: {
                  fontSize: size,
                  color: '#e9ffff'
                }
              }
            },
            data: [
              {
                value: item.value
              }
            ]
          }
        ]
      }
      return clonedeep(option)
    }
  }
}
</script>

<style lang="less" scoped>
.traffic-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .total-box {
    height: 50px;
    display: flex;
    align-items: center;
    .total-img {
      height: 28px;
      line-height: 50px;
      margin-right: 12px;
    }

    .text {
      font-size: 17px;
      font-weight: 700;
      margin-bottom: -10px;
    }
  }
  .gauge-box {
    flex: 1;
    width: 100%;
    height: 100%;
    max-width: 477px;
    .gauge-item {
      .gauge {
        height: 100%;
      }
    }
  }
}
.flop {
  color: #ffffff;
  font-size: 29px;
  margin-right: 5px;
  font-weight: 700;
  text-shadow: 0 0 10px #00c9ce;
  margin-bottom: -6px;
}
</style>
