import request from '@/utils/request'

/**
 * 组织架构列表
 */
 export function getOrg (data) {
  return request({
    url: '/webapi/datascreen/company',
    method: 'GET',
    params: data
  })
}

/**
 * 客户进度
 */
 export function getPlan (data) {
  return request({
      // url: '/webapi/datascreen/schedule',
      url: '/webapi/basic/datascreen/consumer',
      method: 'GET',
      params: data
  })
}

/**
 * 客流量
 */
 export function getTraffic (data) {
  return request({
      // url: '/webapi/datascreen/traffic',
      url: '/webapi/basic/datascreen/statistics/org/traffic',
      method: 'GET',
      params: data
  })
}

/**
 * 客流量【线路】
 */
 export function getTrafficLine (data) {
  return request({
      url: '/webapi/datascreen/traffic/line',
      method: 'GET',
      params: data
  })
}

/**
 * 广告发布数据
 */
 export function getCategoryPublish (data) {
  return request({
      // url: '/webapi/datascreen/project',
      url: '/webapi/basic/datascreen/adv/project',
      method: 'GET',
      params: data
  })
}

/**
 * 上画率【集团】
 */
 export function getMediaBloc (data) {
  return request({
      url: '/webapi/datascreen/media_scale/bloc',
      // url: '/webapi/basic/datascreen/statistics/media_scale/bloc',
      method: 'GET',
      params: data
  })
}

/**
 * 上画率【公司】
 */
 export function getMedia (data) {
  return request({
    url: '/webapi/datascreen/media_scale/line',
    method: 'GET',
    params: data
  })
}

/**
 *  地图数据
 */
 export function getMap (data) {
  return request({
    url: '/webapi/datascreen/map',
    method: 'GET',
    params: data
  })
}

/**
 *  客户排行榜
 */
 export function getCustomerRank (data) {
  return request({
      // url: '/webapi/datascreen/rank/customer',
      url: '/webapi/basic/datascreen/stats/customer',
      method: 'GET',
      params: data
  })
}

/**
 *  销售员排行榜
 */
 export function getSaleRank (data) {
  return request({
        // url: '/webapi/datascreen/rank/sale',
      url: '/webapi/basic/datascreen/stats/sales',
      method: 'GET',
      params: data
  })
}

/**
 *  线路图
 */
 export function getLineImage (params) {
  return request({
    url: '/webapi/datascreen/line/image',
    method: 'GET',
    params
  })
}
