<template>
  <dv-loading v-if="loading">加载中...</dv-loading>
  <div v-else-if="list && list.length" class="draw-box">
    <div class="tabcur">
      <template v-for="(item, index) in tabList">
        <span @click="tabChange(index)" :class="{ on: tabCur === index }" class="tab-item" :key="index">
          {{ item.name }}
        </span>
      </template>
    </div>
    <div class="charts-box">
      <div class="charts" id="charts"></div>
      <img class="bg" :style="{bottom: (rowNumber * 15) + 'px'}" :src="require('@/assets/image/draw-bg.svg')" />
    </div>
  </div>
  <div class="empty" v-else>暂无数据</div>
</template>

<script>
import * as echarts from 'echarts'
import { getMediaBloc, getMedia } from '@/api'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      myCharts: null,
      tabList: [],
      tabCur: 0,
      list: [],
      rowNumber: 1,
      loading: false
    }
  },
  computed: {
    ...mapState(['orgId'])
  },
  watch: {
    // 监听组织架构切换
    orgId: {
      handler(n) {
        let submit
        this.list = []
        if (n === 0 || n === '0') {
          submit = getMediaBloc()
        } else if (n) {
          submit = getMedia({ organization_id: n })
        }
        this.loading = true
        submit.then(res => {
          if (res.data) {
            this.list =  res.data
            this.tabList = this.list.map(item => {
              return {
                id: item.id,
                name: item.name
              }
            })
            this.loading = false
            // 渲染过list之后才能拿到charts的dom
            this.$nextTick(() => {
              this.myCharts = echarts.init(document.getElementById('charts'))
              this.tabChange(0)
            })
          }
        }).finally(()=>{
          this.loading = false
        })
      },
      immediate: true
    }
  },
  methods: {
    // 切换媒体或线路
    tabChange(i) {
      this.tabCur = i
      this.initEcharts(this.list[i])
    },
    // 初始化图表
    initEcharts(item) {
      this.rowNumber = 1
      // 头部icon
      const top =
        'path://M14.000,-0.000 C21.732,-0.000 28.000,2.686 28.000,6.000 C28.000,9.314 21.732,12.000 14.000,12.000 C6.268,12.000 -0.000,9.314 -0.000,6.000 C-0.000,2.686 6.268,-0.000 14.000,-0.000 Z'
      // 底部icon
      const bottom =
        'path://M14.000,-0.000 C21.732,-0.000 28.000,2.686 28.000,6.000 C28.000,9.314 21.732,12.000 14.000,12.000 C6.268,12.000 -0.000,9.314 -0.000,6.000 C-0.000,2.686 6.268,-0.000 14.000,-0.000 Z'
      // 数据
      var data = item.data.map(t => parseInt(t.value))
      const option = {
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: {
            margin: 20,
            textStyle: {
              color: '#999'
            },
            formatter: (params)=>{
              var newParamsName = "";// 最终拼接成的字符串
              var paramsNameNumber = params.length;// 实际标签的个数
              var provideNumber = 7;// 每行能显示的字的个数
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
              if(rowNumber > this.rowNumber){
                this.rowNumber = rowNumber
              }
              /**
               * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
               */
              // 条件等同于rowNumber>1
              if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                      var tempStr = "";// 表示每一次截取的字符串
                      var start = p * provideNumber;// 开始截取的位置
                      var end = start + provideNumber;// 结束截取的位置
                      // 此处特殊处理最后一行的索引值
                      if (p == rowNumber - 1) {
                          // 最后一次不换行
                          tempStr = params.substring(start, paramsNameNumber);
                      } else {
                          // 每一次拼接字符串并换行
                          tempStr = params.substring(start, end) + "\n";
                      }
                      newParamsName += tempStr;// 最终拼成的字符串
                  }

              } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params;
              }
              //将最终的字符串返回
              return newParamsName
            }
          },
          data: item.data.map(t => t.name)
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 104,
          minorTick: {
            splitNumber: 5,
          },
          axisLabel: {
            margin: 20,
            formatter: '{value} %',
            textStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#2c3649'
            }
          }
        },
        // 分四层堆叠，第一层使柱向后偏移，第二层，底部圆形，第三层真正的数据柱，第四层顶部圆形
        series: [
          {
            name: '辅助',
            type: 'bar',
            stack: 'one',
            itemStyle: {
              barBorderColor: 'rgba(0,0,0,0)',
              color: 'rgba(0,0,0,0)'
            },
            emphasis: {
              itemStyle: {
                barBorderColor: 'rgba(0,0,0,0)',
                color: 'rgba(0,0,0,0)'
              }
            },
            data: [4, 4, 4, 4, 4, 4, 4]
          },
          {
            type: 'scatter',
            animationDuration: 100,
            stack: 'one',
            itemStyle: {
              color: '#2773E6'
            },
            data: data.map(() => {
              const val = {
                value: 0,
                symbol: bottom,
                symbolSize: [28, 12]
              }
              return val
            })
          },
          {
            type: 'bar',
            barWidth: 28,
            stack: 'one',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#286BE9' },
                { offset: 1, color: '#0BCCF9' }
              ])
            },
            label: {
              show: true,
              position: 'top',
              formatter: '{c} %',
              fontSize: 12,
              color: '#E2F6FF'
            },
            data: data
          },
          {
            type: 'scatter',
            animationDuration: 10,
            animationDelay: 900,
            stack: 'one',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#0FBFF6' },
                { offset: 1, color: '#00F4FF' }
              ])
            },
            data: data.map(() => {
              const val = {
                value: 0,
                symbol: top,
                symbolSize: [28, 12]
              }
              return val
            })
          }
        ]
      }
      this.myCharts.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.draw-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tabcur {
    margin-top: 10px;
  }
  .charts-box {
    flex: 1;
    position: relative;
    .charts {
      width: 100%;
      height: 100%;
    }
    .bg {
      position: absolute;
      z-index: -1;
      left: 30px;
      width: calc(100% - 30px);
    }
  }
}
</style>
