<template>
    <div class="main">
      <!-- <vue-draggable-resizable :w="width" :h="height" @dragging="onDrag" @resizing="onResize" :parent="true" > -->
        <img class="main-img" :src="imagePath" alt="">
      <!-- </vue-draggable-resizable> -->
    </div>
</template>

<script>
// import VueDraggableResizable from 'vue-draggable-resizable'
import { mapState } from 'vuex'
export default {
  // components: { VueDraggableResizable },
  data() {
    return {
      width: 0,
      height: 0,
      x: 0,
      y: 0
    };
  },
  props:{
    imagePath:{
      type: String
    }
  },
  computed: {
    ...mapState(['orgId'])
  },

  mounted() {
    // let map = document.querySelector('#mapMain')
    // this.width = map.offsetWidth
    // this.height = map.offsetHeight
  },

  methods: {
    onResize (x, y, width, height) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
    },
    onDrag (x, y) {
      this.x = x
      this.y = y
    }
  },
};
</script>

<style lang="less" scoped>
.main{
  width: 100%;
  height: 100%;
  &-img{
    // transform: scale(1.4) translateY(10%);
  }
}
</style>