<template>
  <span>
    {{ nums }}
  </span>
</template>
<script>
export default {
  props: { number: [String, Number] },
  data() {
    return {
      nums: 0
    }
  },
  watch: {
    number (n) {
      this.scroll(n)
    }
  },
  mounted() {
    this.scroll(this.number)
  },
  methods: {
    scroll(num) {
      const nums = (num + '').split('.')
      const number = parseInt(nums[0])
      const point = nums.length === 2 ? parseInt(nums[1]) : 0
      let numCount = 0
      let pointCount = 0
      const formatNumber = n => {
        var b = parseInt(n).toString()
        var len = b.length
        if (len <= 3) {
          return b
        }
        var r = len % 3
        return r > 0
          ? b.slice(0, r) +
              ',' +
              b
                .slice(r, len)
                .match(/\d{3}/g)
                .join(',')
          : b
              .slice(r, len)
              .match(/\d{3}/g)
              .join(',')
      }
      let timer = setInterval(() => {
        if (pointCount === point && numCount === number) {
          clearInterval(timer)
        }
        numCount += Math.ceil(nums[0] / 300)
        pointCount += nums.length === 2 ? Math.ceil(nums[1] / 300) : 0
        if (numCount >= number) numCount = number
        if (pointCount >= point) pointCount = point
        const count = (Number(numCount + '.' + pointCount)).toString().split('.')
        count[0] = formatNumber(count[0])
        this.nums = count.join('.')
      }, 5)
    }
  }
}
</script>
