import Vue from 'vue'
import App from './App.vue'
import { VueAxios } from './utils/request'
import store from './store'
// import './utils/rem'

import './core/lazy_use'
import './assets/css/iconfont.css'
import './assets/css/animate.css'
import './assets/css/global.less'
  
Vue.config.productionTip = false

Vue.use(VueAxios)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
