<template>
  <div id="app">
    <home/>
  </div>
</template>

<script>
import home from './views/index.vue'

export default {
  name: 'App',
  components: {
    home
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
}
.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
