<template>
  <div class="rank-box">
    <div class="title-box">
      <div class="time">（统计时间段：{{ time }}）</div>
      <div class="tabcur">
        <template v-for="(item, index) in tabList">
          <span @click="tabChange(index)" :class="{ on: tabCur === index }" class="tab-item" :key="index">
            {{ item }}
          </span>
        </template>
      </div>
    </div>
    <dv-loading v-if="loading">加载中...</dv-loading>
    <div class="empty" v-else-if="list.length == 0">暂无数据</div>
    <div v-show="list && list.length" class="rank-list-box">
      <div class="top-box">
        <template v-for="(item, index) in list">
          <div
            class="top-item"
            v-if="item.rank <= 3"
            :class="{ one: item.rank === 1, two: item.rank === 2, three: item.rank === 3 }"
            :key="index"
          >
            <div class="bg-img-box">
              <img class="bg-top" v-if="item.rank === 1" :src="require('@/assets/image/sale-rank1.png')" />
              <img class="bg-top" v-else-if="item.rank === 2" :src="require('@/assets/image/sale-rank2.png')" />
              <img class="bg-top" v-else-if="item.rank === 3" :src="require('@/assets/image/sale-rank3.png')" />
            </div>
            <div class="info">
              <img class="rank-one" v-if="item.rank === 1" :src="require('@/assets/image/rank-one.png')" />
              <img v-if="item.avatar" class="avatar" :src="item.avatar" />
              <span v-else class="iconfont avatar icon-touxiang"></span>
              <span class="name">{{ item.name }}</span>
              <span class="price">
                <scroll-num class="num" :number="toPriceUnit(item.price).num"></scroll-num>
                {{toPriceUnit(item.price).unit}}
              </span>
            </div>
          </div>
        </template>
      </div>
      <div class="rank">
        <swiper v-show="list && list.length > 0" ref="mySwiper" :options="swiperOption" class="ranks-list">
          <template v-for="(item, index) in list">
            <swiper-slide v-if="item.rank > 3" :key="index" class="rank-item">
              <div class="item">
                <span class="rank-no">
                  <i class="text">NO.{{ item.rank }}</i>
                </span>
                <span class="title">{{ item.name }}</span>
                <span class="price">
                  <scroll-num class="num" :number="toPriceUnit(item.price).num"></scroll-num>
                  {{toPriceUnit(item.price).unit}}
                </span>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { toPriceUnit } from '@/utils/utils.js'
import { getSaleRank } from '@/api'
import { mapState } from 'vuex'
import ScrollNum from '@/components/ScrollNum'
let size
export default {
  components: {
    swiper,
    swiperSlide,
    ScrollNum
  },
  data() {
    size = Number(document.documentElement.style.fontSize.replace('px', '')) || 16
    return {
      tabList: ['签约', '回款'],
      tabCur: 0,
      time: '', // 时间
      list: [],
      swiperOption: {
        // 所有的参数同 swiper 官方 api 参数
        direction: 'vertical',
        slidesPerView: 4,
        spaceBetween: size * 0.75,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },
      loading: false
    }
  },
  computed: {
    ...mapState(['orgId']),
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  watch: {
    // 监听组织架构切换
    orgId: {
      handler() {
        this.getList()
      },
      immediate: true
    }
  },
  methods: {
    toPriceUnit,
    // 切换签约回款
    tabChange(i) {
      this.tabCur = i
      this.getList()
    },
    // 加载数据
    getList() {
      this.list = []
      this.loading = true
      getSaleRank({ 
        org_id: this.orgId,
        type: this.tabCur + 1
      }).then(res => {
        if (res.data) {
          this.list = res.data.rows
          this.time = res.data.time
          this.swiper.update()
        }
      }).finally(()=>{
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.rank-box {
  .rank-list-box {
    .rank {
      .ranks-list {
        height: 124px;
        .rank-item {
          .item {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            text-align: left;
            padding: 0 12px 0 15px;
            position: relative;
            width: 100%;
              height: 100%;
            &::after {
              content: '';
              position: absolute;
              z-index: -1;
              left: 0;
              top: 0px;
              margin-top: -2px;
              width: 100%;
              height: 100%;
              transform: skewX(10deg);
              background: linear-gradient(to right, rgba(10, 46, 105, 1) 0%, rgba(10, 46, 105, 0) 100%);
              border: 1px solid #1a3f81;
              border-right: 0;
              border-image: linear-gradient(to right, rgba(10, 46, 105, 1) 0%, rgba(10, 46, 105, 0) 100%);
            }
            .line {
              border-left: 7px solid #00ffff;
              border-bottom: 3.5px solid transparent;
              border-top: 3.5px solid transparent;
              height: 10px;
              width: 0;
              margin-right: 8px;
            }
            .rank-no {
              width: 56px;
              height: 100%;
              display: flex;
              align-items: center;
              .rank-img {
                height: 25px;
              }
              .text {
                font-size: 14px;
                padding: 0;
                color: #fde17b;
              }
            }
            .title {
              font-size: 14px;
            }
            .price {
              flex: 1;
              font-size: 12px;
              text-align: right;
              .num {
                font-weight: 700;
                color: #ff4f66;
              }
            }
          }
        }
      }
    }
  }
}
</style>
