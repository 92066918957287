<template>
  <div class="layout bg" :style="{backgroundImage: 'url(' + require('@/assets/image/bg.png') + ')'}">
    <!-- <div id="dv-full-screen-container" :style="{height, width}"> -->
      <dv-full-screen-container>
        <div v-if="orgInfo" class="content">
          <!-- 头部 -->
          <div class="logo-box">
            <div class="left-line">
              <div class="line" :style="{ backgroundImage: 'url(' + require('@/assets/image/top-line2.png') + ')' }"></div>
              <img :src="require('@/assets/image/top-line1.png')" />
            </div>
            <img class="logo" @click="toggleScreen" :src="require('@/assets/image/top-logo-5.png')" />
            <div class="right-line">
              <img style="transform: rotateY(180deg);" :src="require('@/assets/image/top-line1.png')" />
              <div class="line" :style="{ backgroundImage: 'url(' + require('@/assets/image/top-line2.png') + ')' }"></div>
              <div @click="showOrg = !showOrg" class="org-box on">
                {{ orgInfo.name }} <span class="iconfont icon-xiangxia"></span>
                <ul class="org-list" :style="{ height: showOrg ? orgList.length * 40 + 'px' : '0px' }">
                  <li
                    class="org-item"
                    :class="{ on: orgId === item.id }"
                    @click.stop="changeOrgs(item.id)"
                    v-for="(item, index) in orgList"
                    :key="index"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="head-title" v-if="orgId == 0">地铁媒体数字化经营管理平台数据大屏</div>
          </div>
          <div class="box-content">
            <div class="top-item">
              <div class="item-box left">
                <div class="title">
                  <div class="left-ico"></div>
                  进度管理
                  <div class="right-ico"></div>
                </div>
                <div class="box">
                  <img class="ico top-left" :src="require('@/assets/image/border.png')" />
                  <img class="ico top-right" :src="require('@/assets/image/border.png')" />
                  <img class="ico bottom-left" :src="require('@/assets/image/border.png')" />
                  <img class="ico bottom-right" :src="require('@/assets/image/border.png')" />
                  <Planned></Planned>
                </div>
              </div>
              <div class="item-box center" id="mapMain">
                <div class="item-box center" style="width: 100%;height: 100%;margin:0;padding:0;" v-if="lineImage[orgId]">
                  <div class="title">
                    <div class="left-ico"></div>
                    {{ lineImage[orgId].name }}
                    <div class="right-ico"></div>
                  </div>
                  <div class="box">
                    <img class="ico top-left" :src="require('@/assets/image/border.png')" />
                    <img class="ico top-right" :src="require('@/assets/image/border.png')" />
                    <img class="ico bottom-left" :src="require('@/assets/image/border.png')" />
                    <img class="ico bottom-right" :src="require('@/assets/image/border.png')" />
                    <LineMap :imagePath="lineImage[orgId].url" />
                  </div>
                </div>
                <Map v-else></Map>
              </div>
              <div class="item-box right">
                <div class="right-box">
                  <div class="right-item right-top">
                    <div class="title">
                      <div class="left-ico"></div>
                      广告发布数据
                      <div class="right-ico"></div>
                    </div>
                    <div class="box">
                      <img class="ico top-left" :src="require('@/assets/image/border.png')" />
                      <img class="ico top-right" :src="require('@/assets/image/border.png')" />
                      <img class="ico bottom-left" :src="require('@/assets/image/border.png')" />
                      <img class="ico bottom-right" :src="require('@/assets/image/border.png')" />
                      <Advert></Advert>
                    </div>
                  </div>
                  <div class="right-item right-bottom">
                    <div class="title">
                      <div class="left-ico"></div>
                      销售排行榜
                      <div class="right-ico"></div>
                    </div>
                    <div class="box">
                      <img class="ico top-left" :src="require('@/assets/image/border.png')" />
                      <img class="ico top-right" :src="require('@/assets/image/border.png')" />
                      <img class="ico bottom-left" :src="require('@/assets/image/border.png')" />
                      <img class="ico bottom-right" :src="require('@/assets/image/border.png')" />
                      <SaleRank></SaleRank>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-item">
              <div class="item-box left">
                <div class="title">
                  <div class="left-ico"></div>
                  客流量
                  <div class="right-ico"></div>
                </div>
                <div class="box">
                  <img class="ico top-left" :src="require('@/assets/image/border.png')" />
                  <img class="ico top-right" :src="require('@/assets/image/border.png')" />
                  <img class="ico bottom-left" :src="require('@/assets/image/border.png')" />
                  <img class="ico bottom-right" :src="require('@/assets/image/border.png')" />
                  <Traffic></Traffic>
                </div>
              </div>
              <div class="item-box center">
                <div class="title">
                  <div class="left-ico"></div>
                  上画率
                  <div class="right-ico"></div>
                </div>
                <div class="box">
                  <img class="ico top-left" :src="require('@/assets/image/border.png')" />
                  <img class="ico top-right" :src="require('@/assets/image/border.png')" />
                  <img class="ico bottom-left" :src="require('@/assets/image/border.png')" />
                  <img class="ico bottom-right" :src="require('@/assets/image/border.png')" />
                  <Drawing></Drawing>
                </div>
              </div>
              <div class="item-box right">
                <div class="title">
                  <div class="left-ico"></div>
                  客户排行榜
                  <div class="right-ico"></div>
                </div>
                <div class="box">
                  <img class="ico top-left" :src="require('@/assets/image/border.png')" />
                  <img class="ico top-right" :src="require('@/assets/image/border.png')" />
                  <img class="ico bottom-left" :src="require('@/assets/image/border.png')" />
                  <img class="ico bottom-right" :src="require('@/assets/image/border.png')" />
                  <CustomerRank></CustomerRank>
                </div>
              </div>
            </div>
          </div>
        </div>
        <dv-loading v-else>加载中...</dv-loading>
      </dv-full-screen-container>
    <!-- </div> -->
  </div>
</template>

<script>
import Map from './Map.vue'
import LineMap from './LineMap.vue'
import Planned from './Planned.vue'
import Traffic from './Traffic.vue'
import Drawing from './Drawing.vue'
import CustomerRank from './CustomerRank.vue'
import SaleRank from './SaleRank.vue'
import Advert from './Advert.vue'
import { getOrg, getLineImage } from '@/api'
import { mapState, mapMutations } from 'vuex'
import { fullScreen, exitFullScreen } from '@/utils/utils.js'
export default {
  name: 'Index',
  data() {
    return {
      orgList: [],
      orgInfo: {},
      screen: false,
      showOrg: false,

      lineImage: {}
    }
  },
  components: {
    Map,
    LineMap,
    Planned,
    Traffic,
    Drawing,
    CustomerRank,
    SaleRank,
    Advert
  },
  computed: {
    ...mapState(['orgId'])
  },
  watch: {
    // 监听组织架构切换
    orgId: {
      handler(n) {
        this.changeOrgs(n)
      }
    }
  },
  created() {
    // 获取组织架构
    getOrg().then(res => {
      if (res.data) {
        this.orgList = res.data
        this.changeOrgs(0)
      }
    })
    // 获取线路图
    getLineImage().then((res) => {
      this.lineImage = res.data
    })
  },
  mounted(){
  },
  methods: {
    ...mapMutations(['changeOrg']),
    // 切换组织架构
    changeOrgs(id) {
      this.orgList.some(item => {
        if (item.id === id) {
          this.orgInfo = item
          this.changeOrg(item.id)
          return true
        }
      })
      this.showOrg = false
    },
    // 全屏
    toggleScreen() {
      this.screen = !this.screen
      this.screen ? fullScreen(document.body) : exitFullScreen()
    }
  }
}
</script>
<style lang="less" scoped>
.layout{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
// #dv-full-screen-container{
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }
.bg {
  color: #fff;
  font-size: 14px;
  background-color: #000b25;
  // width: 100vw;
  // height: 100vh;
  background-size: cover;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .logo-box {
    display: flex;
    width: 100%;
    position: relative;
    .head-title{
      position: absolute;
      left: 50%;
      bottom: -15px;
      transform: translateX(-50%);
      font-size: 28px;
      text-shadow: 0 0 10px #00c9ce;
    }
    .logo {
      padding: 0;
      width: 40%;
      margin: 0 -8% 0 -10%;
      margin-bottom: 20px;
      cursor: pointer;
      // margin-top: 4px;
    }
    .left-line,
    .right-line {
      flex: 1;
      margin-top: 40px;
      padding: 0;
      display: flex;
      height: 8px;
      .line::after {
        content: '';
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        left: 0;
        background-image: linear-gradient(to right, rgba(0, 9, 33, 1), rgba(0, 9, 33, 0));
      }
    }
    .left-line {
      .line {
        background-position: right;
        flex: 1;
        position: relative;
      }
    }
    .right-line {
      margin-left: -1.5%;
      .line {
        transform: rotateY(180deg);
        background-position: right;
        flex: 1;
        &::after {
          width: 10%;
        }
      }
      .org-box {
        margin: 0 50px 0 15%;
        font-size: 16px;
        color: #1689cc;
        margin-top: -8px;
        position: relative;
        cursor: pointer;
        .org-list {
          position: absolute;
          right: 10px;
          z-index: 200;
          list-style: none;
          text-align: center;
          margin: 10px 0 0;
          padding: 0;
          border-radius: 10px;
          overflow: hidden;
          background: rgba(5, 38, 102, 0.9);
          transition: all 0.15s ease;
          .org-item {
            white-space: nowrap;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 15px;
            height: 40px;
            line-height: 40px;
            &.on,
            &:hover {
              color: #02d9fd;
            }
          }
        }
      }
    }
  }
  .box-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 25px 0 10px;
    .top-item,
    .bottom-item {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 0 25px;
      .item-box {
        display: flex;
        padding: 0 0 40px;
        position: relative;
        box-sizing: border-box;
        margin-right: 25px;
        &.left {
          width: 30%;
          margin-left: 25px;
        }
        &.center {
          width: 40%;
        }
        &.right {
          width: 30%;
        }
        .right-box {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-between;
          .right-item {
            position: relative;
            &.right-top {
              height: 55%;
              padding-bottom: 30px;
            }
            &.right-bottom {
              height: 45%;
            }
          }
        }
        .box {
          width: 100%;
          height: 100%;
          clip-path: polygon(
            8px 0,
            calc(100% - 8px) 0,
            100% 8px,
            100% calc(100% - 8px),
            calc(100% - 8px) 100%,
            8px 100%,
            0 calc(100% - 8px),
            0 8px
          );
          border: 2px solid #1a3f81;
          position: relative;
          text-align: center;
          padding: 25px;
          box-sizing: border-box;
          box-shadow: 0px 0px 18px #1a3f81 inset;
        }
      }
    }
    .top-item {
      flex: 3;
    }
    .bottom-item {
      flex: 2;
    }
  }
  .box > .ico {
    position: absolute;
    &.top-left {
      top: -2px;
      left: -2px;
    }
    &.top-right {
      top: -2px;
      right: -2px;
      transform: rotate(90deg);
    }
    &.bottom-left {
      bottom: -2px;
      left: -2px;
      transform: rotate(-90deg);
    }
    &.bottom-right {
      bottom: -2px;
      right: -2px;
      transform: rotate(180deg);
    }
  }
  .title {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #02d9fd;
    line-height: 29px;
    font-size: 18px;
    width: 166px;
    height: 29px;
    opacity: 1;
    background: #052666;
    border: solid #006cc8;
    border-width: 2px 0;
    &::after,
    &::before,
    .left-ico,
    .right-ico {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      z-index: 1;
    }
    &::before,
    .left-ico {
      left: 0;
      transform: translateX(-100%);
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-right: 9px solid #052666;
    }
    &::after,
    .right-ico {
      right: 0;
      transform: translateX(100%);
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-left: 9px solid #052666;
    }
    .left-ico {
      z-index: 0;
      top: -3px;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-right: 12px solid #006cc8;
    }
    .right-ico {
      z-index: 0;
      top: -3px;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 12px solid #006cc8;
    }
  }
}
</style>
