import axios from 'axios'
import {
  VueAxios
} from './axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    // const data = error.response.data
    // 从 localstorage 获取 token
    const token = localStorage.getItem('ACCESS_TOKEN')

    if (error.response.status === 401) {
      if (token) {
        // 
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  // // const token = localStorage.getItem('ACCESS_TOKEN')
  // const token = '1d382829-6a51-4c3c-94fa-eb63901a00a7'
  // // 如果 token 存在
  // // 让每个请求携带自定义 token 请根据实际情况自行修改
  // if (token) {
  //   if (config.method === 'post') {
  //     config.data = {
  //       token,
  //       ...config.data
  //     }
  //   } else if (config.method === 'get') {
  //     config.params = {
  //       token,
  //       ...config.params
  //     }
  //   }
  // }
  
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}