<template>
  <dv-loading v-if="loading">加载中...</dv-loading>
  <div v-else-if="list && list.length" class="maxbox">
    <div class="map" id="map">
      <img class="map-bg" :src="require('@/assets/image/map-bg1.svg')" />
    </div>
    <div class="map-bottom">
      <img class="circle-img img1" :src="require('@/assets/image/map-circle1.svg')" />
      <div class="circle-img img2-box">
        <img class="img2" :src="require('@/assets/image/map-circle2.svg')" />
      </div>
      <img class="circle-img img3" :src="require('@/assets/image/map-circle3.svg')" />
      <img class="circle-img img4" :src="require('@/assets/image/map-circle4.svg')" />
      <div class="circle-img center-box">
        <img class="img5" :src="require('@/assets/image/map-circle5.svg')" />
        <div class="img6"></div>
      </div>
    </div>
  </div>
  <div class="empty" v-else>暂无数据</div>
</template>

<script>
// 1.所有有地铁的城市都显示城市名称
// 2.合作的城市名称前面有圆圈标点
// 3.有圆圈标点的城市点击则切换到当前选择的城市数据
// 4.有星星标点的城市点击则高亮并且切换到该城市，所属区域的中亮
// 5.有圆圈标点的城市光圈波纹
// 6.所有效果需要注意数据切换时的过度效果
import * as echarts from 'echarts'
import gxJsoon from '../assets/js/china_map.json'
import clonedeep from 'lodash.clonedeep'
import { mapState, mapMutations } from 'vuex'
import { getMap } from '@/api'
// 坐标点
const geoCoordMap = {
  上海: [121.4648, 31.2891],
  广州: [113.5107, 23.2196],
  佛山: [112.8955, 23.1097],
  北京: [116.4551, 40.2539],
  深圳: [114.5435, 22.5439],
  成都: [103.9526, 30.7617],
  重庆: [107.7539, 30.1904],
  南京: [118.8062, 31.9208],
  西安: [109.1162, 34.2004],
  武汉: [114.3896, 30.6628],
  杭州: [119.5313, 29.8773],
  长沙: [113.0823, 28.2568],
  郑州: [113.4668, 34.6234],
  天津: [117.4219, 39.4189],
  苏州: [120.6519, 31.3989],
  合肥: [117.29, 32.0581],
  南宁: [108.479, 23.1152],
  宁波: [121.5967, 29.6466],
  南昌: [116.0046, 28.6633],
  昆明: [102.9199, 25.4663],
  青岛: [120.4651, 36.3373],
  沈阳: [123.1238, 42.1216],
  无锡: [120.3442, 31.5527],
  厦门: [118.1689, 24.6478],
  福州: [119.4543, 25.9222],
  长春: [125.8154, 44.2584],
  大连: [122.2229, 39.4409],
  兰州: [103.5901, 36.3043],
  徐州: [117.5208, 34.3268],
  呼和浩特: [111.4124, 40.4901],
  哈尔滨: [127.9688, 45.368],
  贵阳: [106.6992, 26.7682],
  东莞: [113.8953, 22.901],
  太原: [112.3352, 37.9413],
  常州: [119.4543, 31.5582],
  乌鲁木齐: [87.9236, 43.5883],
  石家庄: [114.4995, 38.1006],
  济南: [117.1582, 36.8701],
  温州: [120.498, 27.8119],
  香港: [114.2784, 22.3057],
  台北: [121.539414, 25.073653],
  高雄: [120.315994, 22.679904],
  桃园: [121.083, 25.0],
  澳门: [113.5715, 22.1583]
}
export default {
  name: 'Map',
  data() {
    return {
      list: null,
      regionData: null,
      option: null,
      myChart: null,
      loading: false
    }
  },
  computed: {
    ...mapState(['orgId'])
  },
  watch: {
    // 监听组织架构切换
    orgId: {
      handler(n) {
        // 拿到最初的地图配置数据
        const { option, regionData } = this.initMap(this.list)
        // 如果切换为集团
        if (n === 0 || n === '0') {
          option.geo.regions = regionData
          this.myChart.setOption(option)
        } else if (n) {
          // 切换到分公司
          const data = this.list[this.list.findIndex(item => item.org_id && item.org_id === n)]
          regionData.push({
            name: data.province,
            itemStyle: {
              areaColor: '#34EDFE'
            }
          })
          option.geo.regions = regionData
          this.myChart.setOption(option)
        }
      }
    }
  },
  mounted() {
    // 地图
    echarts.registerMap('china', gxJsoon)
    this.loading = true
    getMap().then(res => {
      const list = res.data
      this.loading = false
      this.list = clonedeep(list)
      const { option, regionData } = this.initMap(list)
      this.regionData = clonedeep(regionData)
      this.option = clonedeep(option)
      this.$nextTick(() => {
        this.myChart = echarts.init(document.getElementById('map'), null, { renderer: 'svg' })
        this.myChart.setOption(option)
        this.myChart.on('click', param => {
          if (param.data) {
            const region = clonedeep(regionData)
            const data = list[list.findIndex(item => item.id === param.data.id)]
            const type = param.componentSubType
            // 点击的是区域主城市
            if (param.componentSubType === 'scatter') {
              // 切换为集团
              this.changeOrg(0)
              data.area.map(n => {
                region.push({
                  name: n,
                  itemStyle: {
                    areaColor: '#34EDFE'
                  }
                })
              })
            }
            // 点击的是区域主城市并且有区域
            if (data.area && data.is_org === 1) {
              if (type === 'effectScatter' && data.is_post === 1) {
                // 点击的是区域主城市并且有区域
                region.push({
                  name: data.province,
                  itemStyle: {
                    areaColor: '#34EDFE'
                  }
                })
              } else {
                // 点击的只是区域主城市并且不是旗下城市
                data.area.map(n => {
                  region.push({
                    name: n,
                    itemStyle: {
                      areaColor: '#34EDFE'
                    }
                  })
                })
              }
            } else if (data.is_post === 1 && data.is_org === 0) {
              // 如果点击的城市是旗下公司
              region.push({
                name: data.province,
                itemStyle: {
                  areaColor: '#34EDFE'
                }
              })
            }
            // 如果点击的城市是分公司城市
            if (data.org_id && param.componentSubType !== 'scatter') {
              this.changeOrg(data.org_id)
            }
            option.geo.regions = region
            this.myChart.setOption(option)
          }
        })
      })
    }).finally(()=>{
      this.loading = false
    })
  },
  methods: {
    ...mapMutations(['changeOrg']),
    // 渲染城市及其点位
    cityPoint(list) {
      // 改变城市名称位置，避免拥挤
      const topCity = ['广州', '合肥']
      const topRightCity = ['深圳', '南京', '无锡', '东莞']
      const topLeftCity = []
      const bottomCity = ['佛山']
      const bottomLeftCity = ['常州', '苏州']
      const bottomRightCity = ['澳门', '桃园']
      return list.map(function(dataItem) {
        let position = ''
        if (topCity.indexOf(dataItem.name) !== -1) {
          position = 'top'
        } else if (topRightCity.indexOf(dataItem.name) !== -1) {
           position = 'insideBottomLeft'
        } else if (topLeftCity.indexOf(dataItem.name) !== -1) {
           position = 'insideBottomRight'
        } else if (bottomCity.indexOf(dataItem.name) !== -1) {
          position = 'bottom'
        } else if (bottomLeftCity.indexOf(dataItem.name) !== -1) {
          position = 'insideTopRight'
        } else if (bottomRightCity.indexOf(dataItem.name) !== -1) {
          position = 'insideTopLeft'
        }
        const val = {
          name: dataItem.name,
          id: dataItem.id,
          value: geoCoordMap[dataItem.name]
        }
        if (position) {
          val.label = { position }
        }
        return val
      })
    },
    // 初始化地图及点位数据
    initMap(list) {
      const series = []
      // 城市图标
      const cirlcSymbol =
        'path://M512 960C264.576 960 64 759.424 64 512S264.576 64 512 64s448 200.576 448 448-200.576 448-448 448z m0-268.8a179.2 179.2 0 1 0 0-358.4 179.2 179.2 0 0 0 0 358.4z'
      // 星星图标
      const starSymbol =
        'path://M512.00058254 91.69181469l143.48772239 264.17854691 291.81891242 56.91307122L744.16683691 632.97025593l36.86856704 299.33792938L512.00058254 804.19963677l-269.05694321 128.10854854 36.88253894-299.33792938L76.69161984 412.78343282l291.81774734-56.91307122L512.00058254 91.69181469'
      // 地图标点基础配置
      const sericeOptions = {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        zlevel: 2,
        symbol: 'circle',
        rippleEffect: {
          brushType: 'fill'
        },
        tooltip: {
          formatter: '{b}'
        },
        label: {
          normal: {
            show: true,
            fontSize: 10,
            color: '#fff',
            position: 'right',
            formatter: '{b}'
          }
        },
        itemStyle: {
          color: '#ffffff'
        }
      }
      // 区域主城市 只显示星，点击亮区域
      const orgList = []
      // 旗下公司 显示园， 点击亮省
      const postList = []
      // 有地铁的城市 显示小圆圈，不可点击
      const noList = []
      // 即是区域主城市又是分公司主城市  显示星，并且旁边有园, 点击亮区域
      const areaList = []
      list.forEach(item => {
        if (item.is_org === 1 && item.is_post === 0) {
          orgList.push(item)
        } else if (item.is_org === 0 && item.is_post === 0) {
          noList.push(item)
        } else if (item.is_post === 1) {
          if (item.is_org === 1) {
            areaList.push(item)
          }
          postList.push(item)
        }
      })
      // 区域主城市并且不是分公司
      if (orgList.length > 0) {
        const opt = clonedeep(sericeOptions)
        opt.type = 'scatter'
        opt.symbol = starSymbol
        opt.symbolSize = 15
        opt.data = this.cityPoint(orgList)
        series.push(opt)
      }
      // 旗下公司
      if (postList.length > 0) {
        const opt = clonedeep(sericeOptions)
        opt.symbolSize = 6
        opt.rippleEffect.color = 'rgba(255, 255, 255, 0.4)'
        opt.rippleEffect.scale = 4
        // 即是旗下公司，还是分公司 显示园, 大波纹， 点击亮省，并切换组织架构
        if (postList.some(item => item.org_id === this.orgId)) {
          const opts = clonedeep(opt)
          opts.rippleEffect.scale = 15
          opts.data = this.cityPoint(postList.filter(item => item.org_id === this.orgId))
          series.push(opts)
        }
        opt.data = this.cityPoint(postList.filter(item => item.org_id !== this.orgId))
        series.push(opt)
      }
      // 有地铁的城市
      if (noList.length > 0) {
        const opt = clonedeep(sericeOptions)
        ;(opt.symbol = cirlcSymbol), (opt.symbolSize = 6)
        opt.rippleEffect.color = '#00c9ce'
        opt.rippleEffect.scale = 0
        opt.data = this.cityPoint(noList)
        series.push(opt)
      }
      // 区域主城市并且是分公司
      if (areaList.length > 0) {
        const opt = clonedeep(sericeOptions)
        opt.type = 'scatter'
        opt.symbol = starSymbol
        opt.symbolSize = 15
        opt.label.normal.show = false
        opt.data = this.cityPoint(areaList).map(item => {
          const value = clonedeep(item.value)
          value[0] = value[0] - 1.5
          item.value = value
          return item
        })
        series.push(opt)
      }
      let regionData = [
        // {
        //   name: '南海诸岛',
        //   itemStyle: {
        //     normal: {
        //       opacity: 0,
        //       label: {
        //         show: false
        //       }
        //     }
        //   }
        // }
      ]
      list.map(item => {
        if (item.is_post === 1 || item.is_org === 1) {
          regionData.push({
            name: item.province,
            itemStyle: {
              areaColor: '#0A136F'
            }
          })
        }
      })
      const option = {
        title: {
          left: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: 'bottom',
          left: 'right',
          textStyle: {
            color: '#fff'
          },
          selectedMode: 'single'
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '0%',
          containLabel: true
        },
        geo: {
          map: 'china',
          zoom: 1.2,
          label: {
            emphasis: {
              itemStyle: {
                areaColor: '#fff'
              },
              show: false
            }
          },
          emphasis: {
            itemStyle: {
              areaColor: '#00C9CE',
              color: '#fff'
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#00074E',
              borderColor: '#00C9CE'
            }
          },
          regions: regionData
        },
        series: series
      }
      return { option, regionData }
    }
  }
}
</script>

<style lang="less" scoped>
.maxbox {
  position: relative;
  width: 100%;
  height: 100%;
  .map {
    width: 100%;
    height: 95%;
    background-repeat: no-repeat;
    background-size: 90% 90%;
    background-position: center;
  }
  .map-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -49%);
    width: 91%;
    z-index: -1;
  }
  .map-bottom {
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 35%) rotateX(70deg);
    left: 50%;
    width: 50%;
    padding-bottom: 50%;
    z-index: -20;
    .circle-img {
      position: absolute;
      &.img1 {
        top: 0;
        left: 0;
        width: 100%;
        animation: rotaeAll 3s linear 0s infinite;
      }
      &.img2-box {
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        animation: rotaeRevers 4s linear 0s infinite;
        .img2 {
          position: absolute;
          bottom: 1%;
          left: -1%;
          width: 86%;
        }
      }
      &.img3 {
        top: 10%;
        left: 10%;
        width: 80%;
        animation: rotaeAll 5s linear 0s infinite;
      }
      &.img4 {
        top: 15%;
        left: 15%;
        width: 70%;
        animation: rotaeRevers 6s linear 0s infinite;
      }
      &.center-box {
        width: 60%;
        height: 60%;
        top: 20%;
        left: 20%;
        animation: rotaeAll 7s linear 0s infinite;
        .img5 {
          position: absolute;
          top: -5%;
          left: -5%;
          width: 61%;
        }
        .img6 {
          position: absolute;
          top: 7.5%;
          left: 7.5%;
          width: 85%;
          height: 85%;
          border-radius: 50%;
          border: 2px solid #00639e;
          background-image: linear-gradient(to bottom, rgba(0, 134, 216, 0.65882) 0%, rgba(0, 134, 216, 0) 100%);
        }
      }
    }
  }
}
</style>
