<template>
  <dv-loading v-if="loading">加载中...</dv-loading>
  <dv-scroll-board v-else-if="list && list.length" :config="config" class="plan-box" />
  <div class="empty" v-else>暂无数据</div>
</template>

<script>
import { getPlan } from '@/api'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      stage: [],
      list: [],
      config: {},
      loading: false
    }
  },
  computed: {
    ...mapState(['orgId'])
  },
  watch: {
    // 监听组织架构切换
    orgId: {
      handler(n) {
        if (n || n === 0) {
          this.list = []
          this.loading = true
          getPlan({
            organization_id: n,
            page: 1,
            limit: 50
          }).then(res => {
            if (res.data) {
              this.stage = res.data.stage
              this.list = res.data.rows
              // 赋值必须赋值新的对象
              this.config = {
                waitTime: 10000,
                carousel: 'single',
                data: this.list.map((item, index) => {
                  return {
                    name: this.formatItem(item, index)
                  }
                })
              }
            }
          }).finally(()=>{
            this.loading = false
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    // 进度百分比计算
    stageWidth(status) {
      let progress = 0
      const index = this.stage.findIndex(item => item.status === status)
      if (index === -1) {
        progress = 1
      } else {
        progress = (index + 1) / this.stage.length
      }
      return `${progress * 100}%`
    },
    // 格式化数据成html后渲染
    formatItem(item, index) {
      let str = `<div class="item ${index % 3 === 0 ? 'zero' : index % 3 === 1 ? 'one' : index % 3 ? 'two' : ''}" >
      <div class="title-box">
        <div class="title-border"></div>
        <div class="level-tag ${
          item.level_name.replace(/（.+）/g, '') === 'S++'
            ? 'one'
            : item.level_name.replace(/（.+）/g, '') === 'S+'
            ? 'two'
            : item.level_name.replace(/（.+）/g, '') === 'S'
            ? 'three'
            : item.level_name.replace(/（.+）/g, '') === 'A++'
            ? 'four'
            : item.level_name.replace(/（.+）/g, '') === 'A+'
            ? 'five'
            : ''
        }" >${item.level_name.replace(/（.+）/g, '')}</div>
        <div class="text">${item.name}</div>
      </div>
      <div class="progress-bg">
        <div class="progress-box">
          <div style="${'width:' + (item.status ? this.stageWidth(item.status) : '0%')}">
            <div class="progress animated widthChange">
              <div class="cirl"></div>
            </div>
          </div>
          <div class="icox-box">`
      this.stage.map((stage, idx) => {
        if (this.stage.length !== (1 + idx)) {
          str += `<div
                    class="icox-item ${ stage.status === item.status ? 'now' : ''} ${ this.stage.findIndex(t => t.status === item.status) > idx ? 'on' : 'no'}"
                  >
                    <div class="ico1 animated progessLignt  iconfont icon-ziyuanldpi" style="animation-delay:${idx *
                      0.3}s"></div>
                    <div class="ico2 animated progess iconfont icon-ziyuanldpi"style="animation-delay:${idx * 0.3 +
                      0.1}s"></div>
                  </div>`
        }
      })
      str += `</div>
        </div>
      </div>
      <div class="planed-box">`
      this.stage.map(stage => {
        str += `<div class="planed-item ${stage.status === item.status ? 'on' : ''}">
          ${stage.title}
        </div>`
      })
      str += `</div>
    </div>`
      return str
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .row-item .ceil {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
/deep/ .item {
  .title-box {
    display: flex;
    margin-bottom: 20px;
    .title-border {
      margin-right: 12px;
      width: 4px;
      height: 18px;
      background: #007ffe;
    }
    .level-tag {
      margin-right: 8px;
      width: 42px;
      height: 18px;
      line-height: 18px;
      border-radius: 9px 9px 9px 0px;
      color: #fff;
      text-shadow: 0px 0px 10px #fff;
      &.one {
        background: linear-gradient(180deg, #ff4f66 0%, #a91f1f 100%);
      }
      &.two {
        background: linear-gradient(180deg, #f1cf67 0%, #957d1d 100%);
      }
      &.three {
        background: linear-gradient(180deg, #5379FF 0%, #2436B9 100%);
      }
      &.four {
        background: linear-gradient(180deg, #00bcd7 0%, #116774 100%);
      }
      &.five {
        background: linear-gradient(180deg, #1182ff 0%, #135690 100%);
      }
    }
    .text {
      color: #fff;
      line-height: 18px;
    }
  }
  .progress-bg {
    width: 100%;
    padding: 1px;
    box-sizing: border-box;
    border-radius: 100px;
    .progress-box {
      background-color: #000b25;
      width: 100%;
      box-sizing: border-box;
      height: 15px;
      padding: 2px 3px;
      border-radius: 100px;
      display: flex;
      position: relative;
      overflow: hidden;
      .progress {
        height: 11px;
        border-radius: 100px;
        position: relative;
        .cirl {
          content: '';
          position: absolute;
          z-index: 1;
          right: -3px;
          top: -1px;
          width: 13px;
          height: 13px;
          background: #fff;
          border-radius: 50%;
          transform: scale(0.75, 1);
        }
      }
      .icox-box {
        position: absolute;
        top: 0px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        .icox-item {
          .iconfont {
            display: inline-block;
            font-size: 15px;
            line-height: 15px;
            letter-spacing: -8px;
            animation-duration: 4s;
              animation-timing-function: linear;
              // animation-delay: 5s;
              animation-iteration-count: infinite;
              animation-direction: normal;
          }
          &.no {
            .iconfont {
              animation: none;
              opacity: 0.4 !important;
            }
          }
          &.on {
            .ico1 {
              opacity: 0.4;
            }
            .ico2 {
              opacity: 0.8;
            }
          }
          &.now {
            .iconfont {
              transition-delay: 1s;
              transition: all .1s;
              opacity: 0 !important;
            }
          }
        }
      }
    }
  }
  .planed-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .planed-item {
      opacity: 0.7;
      &.on {
        opacity: 1;
      }
    }
  }
  &.zero {
    color: #00e3ff;
    .progress-bg {
      background-image: linear-gradient(to left, rgba(0, 197, 224, 1) 0%, rgba(1, 33, 56, 1) 100%);
      .progress-box {
        .progress {
          background-image: linear-gradient(to left, rgba(0, 197, 224, 1), rgba(1, 33, 56, 1));
        }
      }
    }
  }
  &.one {
    color: #008aff;
    .progress-bg {
      background-image: linear-gradient(to left, rgba(1, 121, 254, 1), rgba(0, 46, 106, 1));
      .progress-box {
        .progress {
          background-image: linear-gradient(to left, rgba(1, 121, 254, 1), rgba(0, 46, 106, 1));
        }
      }
    }
  }
  &.two {
    color: #fee17b;
    .progress-bg {
      background-image: linear-gradient(to left, rgba(244, 214, 119, 1), rgba(49, 53, 55, 1));
      .progress-box {
        .progress {
          background-image: linear-gradient(to left, rgba(244, 214, 119, 1), rgba(49, 53, 55, 1));
        }
      }
    }
  }
}
.plan-box {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
</style>
